import React, { useEffect, useState } from 'react'
import { IoMenu } from 'react-icons/io5'
import data from '../../data'
import AccordionPanel from '../Accordion/AccordionPanel'
import Button from '../Button/Button'
import './Header.css'

const Header = () => {
    const [scrollDown, setScrollDown] = useState(false)
    const [mainMenu, setMainMenu] = useState(false)
    const menuHandler = () => {
        setMainMenu(mainMenu ? false : true) 
    }
    useEffect(() => {
        var prevScrollpos = window.pageYOffset;
        window.onscroll = function() {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                setScrollDown(false)
            } else {
                setScrollDown(true)
                setMainMenu(false)
            }
            prevScrollpos = currentScrollPos;
        }

    }, [])
    
    return (
        <header className='header' aria-label={scrollDown}>
            <div className="flex flex-col responsive lg:flex-row lg:justify-between">
                <div className="flex items-center justify-between">
                    <a href="/">
                        <img className='h-6' src='./assets/image/logo/logo.png' alt='logo'/>
                    </a>
                    <span className='text-2xl lg:hidden' onClick={menuHandler}>
                        <IoMenu/>
                    </span>
                </div>
                <HeaderMenu active={mainMenu} menuHandler={menuHandler} />
            </div>        
      </header>
    )
}

const HeaderMenu = ({active, menuHandler}) => {
    const [mobileMenu, setMobileMenu] = useState(true)
    const checkWindowSize = () => {
        window.innerWidth < 1024 ? setMobileMenu(true) : setMobileMenu(false)
    }
    useEffect(() => {
        checkWindowSize()
        window.addEventListener('resize', checkWindowSize)
        return () => { window.removeEventListener('resize', checkWindowSize) }
    }, [])
    return (
        mobileMenu ? (
            <AccordionPanel active={active}>
            <ul className="flex flex-col pt-5 space-y-6 text-sm">
                {data.links.map((link, idx) => (
                <li key={idx}>
                    {idx !== data.links.length - 1 ? (
                        <a href={link.path} className='link-text' onClick={menuHandler}>{link.name}</a>
                    ) : (
                        <Button buttonOnClick={menuHandler}>
                            <a href={link.path}>{link.name}</a>
                        </Button>
                    )}
                </li>
                ))}
            </ul>
            </AccordionPanel>
        ) : (
            <ul className="flex items-center space-x-8 text-sm">
            {data.links.map((link, idx) => (
                <li key={idx}>
                {idx !== data.links.length - 1 ? (
                    <a href={link.path} className='link-text'>{link.name}</a>
                ) : (
                    <Button>
                    <a href={link.path}>{link.name}</a>
                    </Button>
                )}
                </li>
            ))}
            </ul>
        )
    )
}

export default Header