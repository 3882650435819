import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoChevronForward, IoChevronBack } from 'react-icons/io5'
import './AboutSlider.css'
import 'swiper/css';
import { Navigation } from 'swiper';

const AboutSlider = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    return (
        <div className="about-slider">                   
            <Swiper
               
                spaceBetween={50}
                slidesPerView={1}
                className='overflow-hidden rounded-xl'
                loop={true}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                modules={[Navigation]}
            >   
                {Array(5).fill('').map((_, idx) => (
                    <SwiperSlide key={idx}>
                        <div className="pt-[67%] relative  overflow-hidden rounded-xl">
                            <img className='absolute inset-0 object-cover object-center w-full h-full' src={`./assets/image/about/about-${idx+1}.jpg`} alt="hero" />
                        </div>
                    </SwiperSlide>
                ))}
                <div ref={navigationPrevRef} className={'custom-navigation-prev'}>
                    <span><IoChevronBack/></span>
                </div>
                <div ref={navigationNextRef} className={'custom-navigation-next'}>
                    <span><IoChevronForward/></span>
                </div>
            </Swiper>
        </div>
    )
}

export default AboutSlider