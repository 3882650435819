import React, { useEffect, useRef } from 'react'
import './Accordion.css'

const AccordionPanel = ({children, active}) => {
    const panel = useRef()
    useEffect(() => {
        const panelScrollHeight = panel.current.scrollHeight
        if(active) {
            panel.current.style.maxHeight = `${panelScrollHeight}px`
            // console.log(true)
        } else {
            panel.current.style.maxHeight = ``
            // console.log(false)
        }
    }, [active, panel])
    return (
        <div ref={panel} className="accordion__panel">
          {children}
        </div>
    )
}

export default AccordionPanel