import React, { useEffect, useRef, useState } from 'react'
import data from '../../../data'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import InputTitle from '../../Input/InputTitle'
import InputValidation from '../../Input/InputValidation'
import emailjs from '@emailjs/browser';
import { FaSpinner } from 'react-icons/fa'
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5'

const ContactSection = () => {
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(undefined)

    const form = useRef();
    
    const onChangeHandler = (e, func) => {
      const stringValue = e.target.value.toString()
      func(stringValue)
    }

    const validateFullName = () => {
      if (fullName === '') return 'Nama harus diisi'
      return null
    }
    const validateEmail = () => {
      if (email === '') return 'Email harus diisi'
      const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!pattern.test(email)) return 'Masukkan email yang benar'
      return null
    }
    const validateMessage = () => {
      if (message === '') return 'Pesan harus diisi'
      if (message.length < 3) return 'Minimal pesan 3 huruf'
      return null
    }

    const validateContactForm = () => {
      let errorList = {}
      errorList.fullName = validateFullName()
      errorList.email = validateEmail()
      errorList.message = validateMessage()
      Object.entries(errorList).forEach(([key, value]) => {if (value === null) delete errorList[key]})
      return errorList
    }
    
    const sendEmail = (e) => {
      e.preventDefault();
      let validation = validateContactForm()      
      setErrors(validation)
      if (Object.entries(validation).length > 0) return
      setLoading(true)
      emailjs.sendForm('service_5i2oudn', 'template_yf5eus4', form.current, 'ubALsYNgnyx9zx8iS')
        .then((result) => {
            setLoading(false)
            setSuccess(true)
        }, (error) => {
            setLoading(false)
            setSuccess(false)
        });
    };

    useEffect(() => {
      if(typeof success !== 'boolean') return
      setTimeout(() => {
        setSuccess(false)
      }, 10*1000)
    }, [success])
  
    return (
      <section id='contact'>
        <div className="flex flex-col py-20 space-y-20 responsive lg:flex-row lg:space-y-0 lg:space-x-12">
          <div className="flex flex-col w-full space-y-6 lg:w-1/2">
            <div className="">
              <h2 className="text-3xl font-semibold">Hubungi Kami</h2>
            </div>
            <div className="flex flex-col space-y-4">
              {data.contacts.map((contact, idx) => (
                <div key={idx} className={`flex space-x-2`}>
                  <span className="flex items-center h-5 text-lg text-main-secondary">{contact.icon}</span>
                  <span className='text-sm'>{contact.content}</span>
                </div>
              ))}
              
            </div>
            <div className="relative pt-[65%] overflow-hidden">
           
              <iframe className='absolute inset-0' title='truckinc' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.6740344551135!2d107.60824107594439!3d-6.92950956783054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e882c0b68355%3A0x3009d0419a2f13c5!2sJl.%20Pungkur%20No.157%2C%20Balonggede%2C%20Kec.%20Regol%2C%20Kota%20Bandung%2C%20Jawa%20Barat%2040252!5e0!3m2!1sen!2sid!4v1690911249863!5m2!1sen!2sid" width="100%" height="100%" loading="lazy"></iframe>
            </div>
          </div>
          <form ref={form} className='flex flex-col w-full p-5 space-y-4 border border-gray-200 rounded-xl lg:w-1/2 lg:p-10' onSubmit={sendEmail}>
            <Input title={'NAMA LENGKAP'} inputValue={fullName} inputName={'user_name'} inputOnChange={(e) => onChangeHandler(e, setFullName)} validation={errors && errors.fullName}/>
            <Input title={'EMAIL'} inputValue={email} inputType={"email"} inputName={'user_email'} inputOnChange={(e) => onChangeHandler(e, setEmail)} validation={errors && errors.email}/>
            <div className="flex flex-col space-y-2">
              <InputTitle>PESAN</InputTitle>
              <div className="flex flex-col space-y-1">
                <textarea value={message} className={`textarea ${errors.message ? 'input--error' : ''}`} name="message" id="message" cols="30" rows="8" maxLength={200} onChange={(e) => onChangeHandler(e, setMessage)}/>
                <div className="flex items-center justify-between w-full">
                  {errors && errors.message && <InputValidation>{errors.message}</InputValidation> }
                  <div className="flex-1 text-xs text-right">{message.length} / 200</div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end flex-1 pt-4 text-white">              
              <Button>
                {
                  loading 
                  ? <FaSpinner className='animate-spin' />
                  : 'Kirim Pesan'
                }
              </Button>
            </div>
            {
              typeof success === 'boolean' ? (
                success === true ? (
                  <div className='flex items-center justify-end space-x-2 text-green-600'>
                    <span><IoCheckmarkCircle /></span>
                    <span>Pesan berhasil dikirim</span>
                  </div>
                ) : (
                  <div className='flex items-center justify-end space-x-2 text-red-600 '>
                    <span><IoCloseCircle /></span>
                    <span>Pesan gagal dikirm</span>
                  </div>                  
                )
              ) : ''
            }
          </form>
        </div>
      </section>
    )
}

export default ContactSection