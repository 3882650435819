import React, { useEffect, useState } from 'react'
import Input from '../Input/Input'
import './Select.css'

const Select = ({
  validation,
  title,
  icon,
  inputValue,
  inputName,
  data,
  optionOnSelect 
}) => {
  const [value, setValue] = useState(inputValue)
  const [options, setOptions] = useState(false)
  const [optionsData, setOptionsData] = useState(data)


  function onChangeHandler(e) {
    const targetValue = e.target.value
    setValue(targetValue)
    setOptions(true)
    processData(targetValue)
  }
  function onSelectHandler(list) {
    optionOnSelect && optionOnSelect(list)
    setValue(list.name)
    setOptions(false)
  }
  const processData = (_text) => {
    _text = _text.toLowerCase()
    let dataToSet = []
    data.forEach((obj) => {
      const objName = obj.name.toLowerCase()
      if(objName.includes(_text)) {
        dataToSet.push(obj)
      }
    })
    setOptionsData(dataToSet)
  }

  useEffect(function() {
    function windowOnClick(e) {
      const targetName = e.target.getAttribute('name')
      if(targetName && targetName.includes(inputName)) return
      setOptions(false)
    }
    window.addEventListener('click', windowOnClick)
    return function() { window.removeEventListener('click', windowOnClick) }
  })
  
  
  return (
    <div className="relative w-full">
      <Input
        title={title}
        icon={icon}
        inputValue={value}
        inputOnChange={onChangeHandler}
        inputOnFocus={onChangeHandler}
        validation={validation}
        inputName={inputName}
      />
      <div className="options" aria-label={options}>            
        {optionsData.length > 0 ? (
          optionsData.map((list, idx) => (
            <span
              key={`${inputName}-${idx}`} 
              className="w-full px-3 py-2 transition duration-200 cursor-pointer hover:bg-gray-100"
              onClick={() => onSelectHandler(list)}
            >
              {list.name}
            </span>
          ))
        ) : (
          <span className="w-full px-3 py-2 transition duration-200 ">
            Kota tidak ditemukan
          </span>
        )}
      </div>
  </div>
  )
}

export default Select