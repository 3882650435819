import React from 'react'
import InputTitle from './InputTitle'
import InputValidation from './InputValidation'
import './Input.css'

const Input = ({title, icon, inputRef, inputName, inputId, inputValue, inputOnChange, inputType, inputPlaceholder, inputOnFocus, inputOnBlur, validation}) => {
    const CHECK_TITLE = title ? <InputTitle>{title}</InputTitle> : ''
    const CHECK_ICON = icon ? <img className='absolute w-5 h-5 left-3' src={icon} alt={icon} /> : ''
    const CHECK_FOR_PADDING = icon ? 'pl-10' : 'pl-3'
    const CHECK_VALIDATION = validation ? <InputValidation>{validation}</InputValidation> : ''
    const CHECK_INPUT_TYPE = inputType || 'text'
    return (
        <label htmlFor={inputName} className='flex flex-col w-full space-y-2'>
            {CHECK_TITLE}
            <div className="flex flex-col w-full">
                <span className='relative flex items-center w-full'>
                    {CHECK_ICON}
                    <input 
                        ref={inputRef}
                        id={inputId} 
                        className={`input ${validation ? 'input--error' : ''} ${CHECK_FOR_PADDING}`} 
                        type={CHECK_INPUT_TYPE}
                        name={inputName} 
                        value={inputValue}
                        placeholder={inputPlaceholder}
                        onChange={inputOnChange} 
                        onFocus={inputOnFocus}
                        onBlur={inputOnBlur}
                        autoComplete={"off"}
                    />
                </span>
                {CHECK_VALIDATION}
            </div>
        </label>
    )
}

export default Input