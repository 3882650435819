import { useCallback } from "react"

export const useValidation = () => {
  const validateValue = useCallback((data) => {

    let simError = {}

    if (data.pickUp !== '' && data.drop !== '' && data.pickUp === data.drop) {
        simError.pickUp = 'Kota Pengambilan tidak bisa sama dengan Kota Tujuan'
        simError.drop = 'Kota Tujuan tidak bisa sama dengan Kota Pengambilan'
    } else {
        delete simError.pickUp
        delete simError.drop
    }

    if (data.pickUp === '') {
        simError.pickUp = 'Masukan Kota Pengambilan'
    } else {
        delete simError.pickUp
    }
    
    if (data.drop === '') {
        simError.drop = 'Masukan Kota Tujuan'
    } else {
        delete simError.drop
    }

  
    if (data.width === '' || data.lgth === '' || data.height === '') {
        simError.dimension = 'Lengkapi Dimensi'
    } else {
        delete simError.dimension
    } 


    if (data.weight === '') {
        simError.weight = 'Masukan Bobot'
    } else if (Number(data.weight) < 1) {
        simError.weight = 'Bobot minimal 1 Kg'
    } else { 
        delete simError.weight
    }

    return simError
  }, []) 

  return { validateValue }
}