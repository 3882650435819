import React from 'react'
import './AboutSection.css'
import Simulation from '../../Simulation/Simulation'
import AboutSlider from '../../Sliders/AboutSlider/AboutSlider'

const AboutSection = () => {
  return (
    <section id='about' className='about'>
        <div className="flex flex-col py-20 responsive">
            <div className="flex flex-col space-y-16 xl:flex-row xl:space-y-0 xl:space-x-12 xl:items-center">
                <AboutSlider/>
                <div className="flex flex-col space-y-6">
                    <h2 className="text-3xl font-semibold">Cargo and Beyond</h2>
                    <p className='flex flex-col space-y-4'>
                        <span className=''>Truckinc Melayani kebutuhan pengiriman barang Perusahaan maupun perorangan khususnya Jabodetabek dan Bandung area saat ini.</span>
                        <span className='text-sm opacity-80'>Untuk Perusahaan, Truckinc menyediakan Jasa Management Pengiriman Barang, sehingga Perusahaan dapat Fokus dalam produksi atau memasarkan produk nya.</span>
                        <span className='text-sm opacity-80'>Untuk Perorangan, Truckinc memberikan pelayanan kiriman Same Day Service, maupun Reguler (H+1). Untuk Informasi Rate, Silahkan menggunakan kalkulator dibawah ini.</span>
                    </p>
                </div>
            </div>
            <div  id='simulation' className="flex w-full pt-20">
                <Simulation/>
            </div>
        </div>
    </section>
  )
}

export default AboutSection