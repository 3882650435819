import { IoLocation, IoPhonePortrait } from 'react-icons/io5'

const data = {
    links: [
        {
          name: 'Home',
          path: '/'
        },
        {
          name: 'Tentang',
          path: '#about'
        },
        {
          name: 'Simulasi',
          path: '#simulation'
        },
        {
          name: 'Hubungi Kami',
          path: '#contact'
        },

        {
          name: 'Kebijakan',
          path: '#policy'
        },
        
    ],
    benefits: [
      {
        id: 1,
        title: 'Business To Business Service',
        description: 'Pelayanan jasa management pengiriman barang bagi perusahaan yang sifatnya rutindan dedicated. Dengan system dan laporan bulanan pengiriman barang, memudahkan perusahaan dalam memonitor pengiriman barangnya dan perusahaan dapat Fokus kepada Core Company Business nya.',
        image: './assets/image/icons/benefits/benefit-1.png'
      },
      {
        id: 2,
        title: 'Focus Customer Service',
        description: 'Dapatkan pelayanan, kecepatan dan keramahan terbaik dari petugas kami yang telah terlatih dalam melayani setiap pesanan kamu.Melayani pengiriman kepada customer perorangan maupun perusahaan yang sifatnya On-Call Base. Dengan pelayanan same day service maupun Reguler yaitu H+1.',
        image: './assets/image/icons/benefits/benefit-2.png'
      },
      {
        id: 3,
        title: 'New Services – Frozen Goods Specialist',
        description: 'Dengan menggunakan Frozen truck yang suhunya mencapai -20 Derajat Celsius, Truckinc menjamin barang yang berupa fresh food atau dairy maupun ice cream, dapat terkirim kepada customer dalam keadaan beku maupun segar. ',
        image: './assets/image/icons/benefits/benefit-3.png'
      },
    ],
    contacts: [
      {
        name: 'address',
        content: 'PT TRUCKINC LOGISTIK INDONESIA: Jl. Pungkur No.157, Balonggede, Kec. Regol, Kota Bandung, Jawa Barat 40252, Indonesia',
        icon: <IoLocation/>
      },
      {
        name: 'Email',
        content: 'customerservice@truckinc.id',
        icon: <IoPhonePortrait/>
      },
      {
        name: 'Office',
        content: '(022) 4234370',
        icon: <IoPhonePortrait/>
      },

      {
        name: 'Imas',
        content: 'Imas +62 821-2627-4991',
        icon: <IoPhonePortrait/>
      },
      {
        name: 'Amanda',
        content: 'Amanda +62 812-1870-2685',
        icon: <IoPhonePortrait/>
      },
    ] ,

    priceRanges: [
      {
        id: 'SD',
        name: 'Same Day',
        ranges: [
            {
                id: 1,
                cities: {
                  city1: ['BDG'], 
                  city2: ['JKT', 'BKS']
                },
                range: {
                    firstWeight: 20,
                    firstWeightPrice: 100000,
                    nextWeightPrice: 1750,
                }
            },
            {
                id: 2,
                cities: {
                  city1: ['BDG'], 
                  city2: ['BGR', 'TGS', 'DPO']
                },
                range: {
                    firstWeight: 20,
                    firstWeightPrice: 150000,
                    nextWeightPrice: 2000,
                }
            },
        ]
      },
      {
        id: 'ND',
        name: 'Next Day',
        ranges: [
            {
                id: 1,
                cities: {
                  city1: ['JKT'], 
                  city2: ['BDG', 'BKS']
                },
                range: {
                    firstWeight: 5,
                    firstWeightPrice: 35000,
                    nextWeightPrice: 1250,
                }
            },
            {
                id: 2,
                cities: {
                  city1: ['JKT', 'BDG'], 
                  city2: ['TGR', 'BGR']
                },
                range: {
                    firstWeight: 5,
                    firstWeightPrice: 50000,
                    nextWeightPrice: 1500,
                }
            },
            {
                id: 3,
                cities: {
                  city1: ['JKT', 'BDG'], 
                  city2: ['TGS', 'DPO']
                },
                range: {
                    firstWeight: 5,
                    firstWeightPrice: 75000,
                    nextWeightPrice: 2000,
                }
            },
        ]
      },
      {
        id: 'FZ',
        name: 'Frozen',
        ranges: [
            {
                id: 1,
                cities: {
                  city1: ['BDG'], 
                  city2: ['JKT', 'BKS']
                },
                range: {
                    firstWeight: 20,
                    firstWeightPrice: 100000,
                    nextWeightPrice: 2000,
                }
            },
        ]
      },

    ],
    cities: [
      {
          id: 'BDG',
          name: 'Bandung',
      },
      {
          id: 'BKS',
          name: 'Bekasi',
      },
      {
          id: 'BGR',
          name: 'Bogor',
      },
      {
          id: 'DPO',
          name: 'Depok',
      },
      {
          id: 'JKT',
          name: 'Jakarta',
      },
      {
          id: 'TGR',
          name: 'Tangerang',
      },
      {
          id: 'TGS',
          name: 'Tangerang Selatan',
      },
    ]
}

export default data