import React from 'react'
import Simulation from '../../Simulation/Simulation'
import AboutSlider from '../../Sliders/AboutSlider/AboutSlider'
import './DataPrivacySection.css'

const DataPrivacySection = () => {
  return (
    <section id='policy'>
        <div className="flex flex-col py-20 responsive">
            <div className="flex flex-col space-y-16 xl:flex-row xl:space-y-0 xl:space-x-12 xl:items-center">
                <div className="flex flex-col space-y-6">
                <h2 className="text-3xl font-semibold">Kebijakan</h2>
                    <ul><a href="/privacy.html"><h2 text-2xl font-semibold>Kebijakan Privasi Aplikasi Truckinc Logistik Indonesia</h2></a></ul>
                    <ul><a href="/delivery.html"><h2 text-2xl font-semibold>Kebijakan Pengiriman Truckinc Logistik Indonesia</h2></a></ul>
                    <ul><a href="/return.html"><h2 text-2xl font-semibold>Kebijakan Pengembalian Truckinc Logistik Indonesia</h2></a></ul>
                </div>
            </div>
        </div>
    </section>
  )
}

export default DataPrivacySection