import React from 'react'

const HeroSection = () => {
  return (
    <section className="relative w-full h-screen max-h-screen overflow-hidden xl:max-h-[720px]">
        <img className='absolute inset-0 object-cover object-center w-full h-full' src="./assets/image/hero/hero.jpg" alt="hero" />
    </section>
  )
}

export default HeroSection