import React from 'react'
import data from '../../../data'

const BenefitsSection = () => {
  return (
    <ul className='grid gap-4 mb-20 responsive lg:grid-cols-3 lg:-mt-[10vh] -mt-[24vh]'>
        {data.benefits.map((bft, idx) => (
        <li key={idx} className='flex flex-col p-5 space-y-6 transition duration-200 transform bg-white border border-gray-100 shadow-lg rounded-xl lg:hover:scale-105'>
            <div className="w-16">
            <div className="pt-[100%] relative overflow-hidden">
                <img className='absolute inset-0 object-cover object-center w-full h-full' src={bft.image} alt={`benefit-${bft.id}`} />
            </div>
            </div>
            <div className="flex flex-col space-y-2">
            <h3 className='text-base font-semibold'>{bft.title}</h3>
            <p className='text-sm leading-normal opacity-60'>{bft.description}</p>
            </div>
        </li>
        ))}
    </ul>
  )
}

export default BenefitsSection