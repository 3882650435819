import './App.css';
import Header from './components/Header/Header';
import HeroSection from './components/Sections/HeroSection/HeroSection';
import BenefitsSection from './components/Sections/BenefitsSection/BenefitsSection';
import AboutSection from './components/Sections/AboutSection/AboutSection';
import ClientsSection from './components/Sections/ClientsSection/ClientsSection';
import ContactSection from './components/Sections/ContactSection/ContactSection';
import DataPrivacySection from './components/Sections/DataPrivacySection/DataPrivacySection';
import DeliverySection from './components/Sections/DeliverySection/DeliverySection';
import Footer from './components/Footer/Footer';
import WhatsAppIcon from './components/Button/WhatsAppIcon';
import { BrowserRouter,Routes,Route} from 'react-router-dom'

function App() {
  
  return (

    <>
      <Header/>
      
      <main className='mt-16'>
        <HeroSection/>
        <BenefitsSection/>
        <AboutSection/>
        {/* <ClientsSection/> */}
        {/*<DeliverySection/>*/}
        
        <ContactSection/>
        
        <DataPrivacySection/>
        <WhatsAppIcon />

        

      </main>
      <Footer/>
    </>
  );
}

export default App;
