import React from 'react'
import { FaFacebookF, FaInstagram } from 'react-icons/fa'
import data from '../../data'

const Footer = () => {
    return (
        <footer className='w-full text-white bg-main-primary'>
            <div className="flex flex-col items-center py-8 space-y-6 responsive">
                <div className="flex items-center justify-between w-full">
                    <div className="w-full">
                        <img className='h-6' src='./assets/image/logo/logo.png' alt='logo'/>
                    </div>
                    <ul className="items-center flex-shrink-0 hidden py-5 space-x-8 text-sm lg:flex">
                        {data.links.map((link, idx) => (
                            <li key={idx}>
                            <a href={link.path} className='link-text'>{link.name}</a>
                            </li>
                        ))}
                    </ul>
                    <div className="flex justify-end w-full space-x-2">
                        <a href="https://web.facebook.com/truckinc.id?_rdc=1&_rdr"  target="_blank" rel='noreferrer'  className='flex items-center justify-center flex-shrink-0 w-8 h-8 border border-white rounded-full'>
                            <span><FaFacebookF/></span>
                        </a>
                        <a href="https://www.instagram.com/truckinc.id/?hl=en" target="_blank" rel='noreferrer' className='flex items-center justify-center flex-shrink-0 w-8 h-8 border border-white rounded-full'>
                            <span><FaInstagram/></span>
                        </a>
                    </div>
                </div>

                
                <span className='w-full h-px bg-white opacity-50'></span>
                <span className='text-xs text-center'>2023 truckinc.id copyright all right reserved</span>
            </div>
        </footer>
    )
}

export default Footer