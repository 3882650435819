import React, { useCallback, useEffect, useRef, useState } from 'react'
import Input from '../Input/Input'
import InputTitle from '../Input/InputTitle'
import { IoClose } from 'react-icons/io5'
import Button from '../Button/Button'
import InputValidation from '../Input/InputValidation'
import Select from '../Select/Select'
import data from '../../data'
import { useValidation } from '../../hooks/useValidation'
import { useNumberFormatter } from '../../hooks/useNumberFormatter'

const Simulation = () => {

    const [pickUp, setPickUp] = useState({ id: 'initial', name: ''})
    const [drop, setDrop] = useState({ id: 'initial', name: ''})
    const [lgth, setLgth] = useState('')
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [weight, setWeight] = useState('')
    const [errorList, setErrorList] = useState({pickUp: '', drop: '', dimension: '', weight: ''})
    const [priceList, setPriceList] = useState([])
    const [prices, setPrices] = useState(null)

    const { validateValue } = useValidation()
    const { numberWithCommas } = useNumberFormatter()
   
    const dimensions = [
        {
            id: 1,
            name: 'panjang',
            initial: 'P',
            value: lgth,
            onChange: setLgth
        },
        {
            id: 2,
            name: 'lebar',
            initial: 'L',
            value: width,
            onChange: setWidth
        },
        {
            id: 3,
            name: 'tinggi',
            initial: 'T',
            value: height,
            onChange: setHeight
        },
    ]
      
    useEffect(() => {
        let rangesArray = {
            SD: [],
            ND: [],
            FZ: []
        }
        data.priceRanges.forEach(priceRange => {
            const priceRangeId = priceRange.id
            const ranges = priceRange.ranges
            ranges.forEach(range => {
                const rangeCities = range.cities
                const cities1 = rangeCities.city1
                const cities2 = rangeCities.city2
                cities1.forEach((city1) => {
                    cities2.forEach((city2) => {
                        rangesArray[priceRangeId].push({rangeName: priceRange.name, cities: [city1, city2], ...range.range})
                    })
                })
                
            })
        })
        setPriceList(rangesArray)
       
    }, [])

    const getPrices = () => {
        let priceListArray = []
        Object.entries(priceList).forEach(([key, value]) => {
            value.forEach(val => {
                [`${pickUp.id} - ${drop.id}`, `${drop.id} - ${pickUp.id}`].forEach((type) => {
                    if(`${val.cities[0]} - ${val.cities[1]}` !== type) return
                    const { firstWeight, firstWeightPrice, nextWeightPrice }= val
                    let objectToPush = {id: key, rangeName:val.rangeName, price: firstWeightPrice }
                    if (weight > firstWeight) {
                        const nextWeight = weight - firstWeight
                        objectToPush.price = firstWeightPrice + (nextWeight * nextWeightPrice)
                    }                    
                    priceListArray.push(objectToPush)
                })
            })
        })
        setPrices(priceListArray)
    }

    const simulationOnSubmit = (e) => {
        e.preventDefault()    
        const validation = validateValue({
            pickUp: pickUp.name,
            drop: drop.name,
            lgth: lgth,
            width: width,
            height: height,
            weight: weight,
        })
            
        if(Object.entries(validation).length > 0) return setErrorList(validation)
        getPrices()
    }    

    return (
        <form className="flex flex-col w-full p-5 space-y-5 bg-white rounded-xl text-main-black lg:p-10">
            <div className="flex flex-col w-full space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                <Select
                    title={'KOTA PENGAMBILAN'}
                    icon={'./assets/image/icons/simulation/pickup.png'}
                    inputValue={pickUp.name}
                    inputName={'pickUp'}
                    validation={errorList.pickUp || ''}
                    data={data.cities}
                    optionOnSelect={(option) => setPickUp(option)}
                />
                <Select
                    title={'KOTA TUJUAN'}
                    icon={'./assets/image/icons/simulation/drop.png'}
                    inputValue={drop.name}
                    inputName={'drop'}
                    validation={errorList.drop || ''}
                    data={data.cities}
                    optionOnSelect={(option) => setDrop(option)}
                />
            </div>
            <div className="flex flex-col w-full space-y-2">
                <InputTitle>DIMENSI (CENTIMETER)</InputTitle>
                <ul className="flex items-center w-full space-x-2">
                    {dimensions.map((dmn, idx) => (
                        <li key={idx}  className='flex items-center w-full space-x-2'>
                            <Input 
                                
                                inputPlaceholder={dmn.initial} 
                                inputType={'number'} 
                                value={dmn.value}
                                inputOnChange={(e) => dmn.onChange(e.target.value)}
                                validation={errorList.dimension ? true : false}

                            />
                            {idx !== dimensions.length - 1 ? (
                                <span><IoClose className='text-xxl opacity-70'/></span>
                            ):''}
                        </li>
                    ))}
                </ul>
                {errorList.dimension ? <InputValidation>{errorList.dimension}</InputValidation> : ''}
            </div>
            <Input 
                title={'BOBOT (KG)'}
                inputType={'number'} 
                inputValue={weight}
                inputOnChange={(e) => setWeight(e.target.value)}
                validation={errorList.weight || ''}
            />
            <div className="flex items-center justify-end w-full pt-5">
                <Button buttonOnClick={simulationOnSubmit}>
                    Lihat Harga
                </Button>
            </div>
           
                {prices ? (
                    <>
                        <div className="w-full h-px bg-gray-200"></div>
                        <div className="flex flex-col w-full space-y-4 text-sm">
                            {
                                prices.length > 0 ? (
                                    prices.map((price, idx) => (
                                        <div key={idx} className="flex justify-between w-full">
                                            <span>{price.rangeName}</span>
                                            <span className='font-bold'>Rp {numberWithCommas(price.price)}</span>
                                        </div>
                                    ))
                                ) : (
                                    <span className='w-full text-center'>Layanan belum tersedia untuk saat ini</span>
                                )                                
                            }
                        </div>
                    </>
                ) : ''}
        </form>
    )
}

export default Simulation

