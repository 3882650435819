import React from 'react';

const WhatsAppIcon = () => {
  return (

    <a href="https://wa.me/6281218702685" target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
      <img alt="Chat on WhatsApp" src='./assets/image/logo/WhatsAppButtonGreenSmall.png' />
    </a>
  );
};

export default WhatsAppIcon;